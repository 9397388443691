import { ResumeIdResponseStatuses } from 'src/hooks/useResumeIdResponseStatuses';

export enum RespondedText {
    OpenChat = 'openChat',
}

interface ResponsedProps {
    text: RespondedText;
    chatId?: number;
}

export const getRespondedProps = (resumeIdResponseStatuses: ResumeIdResponseStatuses | null): ResponsedProps | null => {
    if (resumeIdResponseStatuses === null) {
        return null;
    }

    const { responded, invited, discarded, usedResumeIds } = resumeIdResponseStatuses;
    const usedResumeCount = usedResumeIds.length;

    let chatId: number | undefined;

    if (usedResumeCount === 1) {
        if (invited.length === 1) {
            chatId = invited[0].chatId;
        } else if (discarded.length === 1) {
            chatId = discarded[0].chatId;
        } else {
            chatId = responded[0].chatId;
        }
    }

    return {
        text: RespondedText.OpenChat,
        chatId,
    };
};
