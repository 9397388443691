import { useState, useEffect, useRef, useContext } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import { ChatikContext } from '@hh.ru/chatik-integration';
import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import VSpacing from 'bloko/blocks/vSpacing';
import debounce from 'bloko/common/debounce';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import scrollToElement from 'Utils/ScrollToElement';
import Form from 'src/components/Form';
import { useNotification } from 'src/components/Notifications/Provider';
import { ResponseError } from 'src/components/VacancyResponseError';
import { ResponseStep } from 'src/components/VacancyResponsePopup/BottomSheet/responseSteps';
import Source from 'src/components/VacancySearchItem/types/Source';
import useGetResponseQuestion from 'src/components/VacancyView/hooks/useResponseQuestion';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { responseStreakUpdate } from 'src/models/applicantResponseStreaks';
import { vacancyResponseUpdate } from 'src/models/applicantVacancyResponseStatuses';
import { addUserLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import { vacancyChatInfoUpdate } from 'src/models/vacancyChatInfo';
import { ResponseQuestion } from 'src/models/vacancyResponseQuestions';
import UserLabel from 'src/utils/constants/userLabels';
import fetcher from 'src/utils/fetcher';
import scrollToFirstError from 'src/utils/finalForm/scrollToFirstError';
import { UXFeedback } from 'src/utils/uxfeedback';

import VacancyResponseFormAlreadyRespondedPlate from 'src/components/VacancyResponseForm/AlreadyRespondedPlate';
import VacancyResponseFormInconsistencies from 'src/components/VacancyResponseForm/Inconsistencies';
import VacancyResponseFormLetter from 'src/components/VacancyResponseForm/Letter';
import ResumeSelector from 'src/components/VacancyResponseForm/ResumeSelector';
import { createNotification } from 'src/components/VacancyResponseForm/VacancyResponseNotification';

const saveLetterToLocalstorage = debounce((localstorageKey, letterText) => {
    LocalStorageWrapper.setItem(localstorageKey, letterText);
}, 100);

const scrollToRespondedBlock = (blockRef) => {
    setTimeout(() => {
        if (blockRef?.current) {
            scrollToElement(blockRef.current, {
                topOffset: 0,
                centered: false,
            });
        }
    }, 0);
};

export const getUserResumes = (responseStatus) => {
    const resumesIds = [...responseStatus.unusedResumeIds, ...responseStatus.hiddenResumeIds];
    return resumesIds.map((resumeId) => responseStatus.resumes[resumeId]);
};

const applicantActivityAction = makeSetStoreField('applicantActivity');
const decorators = [scrollToFirstError({ name: 'vacancy_response' })];
const NEED_LOGIN_CODE = 'need-login';
const FORM_ID = 'RESPONSE_MODAL_FORM_ID';

const TrlKeys = {
    defaultQuestion: 'vacancy.question.greeting',
};

const ResponseForm = ({
    trls,
    vacancyId,
    resumes,
    submitting,
    setSubmitting,
    onResponse,
    errorCode,
    onError,
    responseStep,
    vacancyBodyFooterNodeRef,
    vacancySource,
    needRedirect,
    isBottomSheet,
    isSkipInconsistencies,
    setResponseData,
    selectedResume,
    setSelectedResume,
    postponedActions,
    isQuestionResponse,
    isFromPopup,
    render = () => {},
    renderContent = () => {},
    renderSubmit = () => {},
}) => {
    const dispatch = useDispatch();
    const openChatik = useContext(ChatikContext)?.openChatik;
    const responseStatus = useSelector((state) => {
        return state.applicantVacancyResponseStatuses[vacancyId];
    });
    const publishedForResponseResumeHash = useSelector((state) => state.publishedForResponseResumeHash);
    const countriesProfileVisibilityAgreement = useSelector((state) => {
        return state.countriesProfileVisibilityAgreement;
    });
    const lastQuestionResponse = useSelector((state) => state.lastQuestionResponse);
    const topics = responseStatus.negotiations.topicList;
    const vacancy = responseStatus.shortVacancy;
    const alreadyResponded = responseStatus.usedResumeIds.length > 0;
    const { addNotification } = useNotification();

    const getVacancyResponseQuestion = useGetResponseQuestion();
    const localstorageLetterKey = `vacancy_response_letter_${vacancyId}`;
    const localstorageQuestionKey = `vacancy_response_question_${vacancyId}`;
    const [letterText, setLetterText] = useState(
        LocalStorageWrapper.getItem(localstorageLetterKey) || getVacancyResponseQuestion(vacancyId)?.value || ''
    );
    const [questionText, setQuestionText] = useState(
        lastQuestionResponse || LocalStorageWrapper.getItem(localstorageQuestionKey) || trls[TrlKeys.defaultQuestion]
    );
    const formRef = useRef();
    const { hhtmFromLabel } = useSelector(({ router }) => router.location.query);
    const hhtmSourceLabel = vacancySource === Source.RelatedVacancies ? 'suitable_vacancies' : undefined;
    const responseQuestion = getVacancyResponseQuestion(vacancyId);
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);

    const isMagritte = useExperiment('magritte_on_vacancy_response');
    const isLetterRequired = vacancy['@responseLetterRequired'];

    useEffect(() => {
        if (errorCode === ResponseError.LetterRequired && letterText.trim().length) {
            onError(null);
        }
        saveLetterToLocalstorage(
            isQuestionResponse ? localstorageQuestionKey : localstorageLetterKey,
            isQuestionResponse ? questionText : letterText
        );
    }, [
        errorCode,
        letterText,
        questionText,
        localstorageQuestionKey,
        localstorageLetterKey,
        onError,
        isQuestionResponse,
    ]);

    useEffect(() => {
        if (!isQuestionResponse && responseQuestion) {
            setLetterText(responseQuestion.value);
        }
    }, [isQuestionResponse, responseQuestion]);

    useEffect(() => {
        if (isQuestionResponse && lastQuestionResponse) {
            setQuestionText(lastQuestionResponse);
        }
    }, [isQuestionResponse, lastQuestionResponse]);

    const runActionFinally = (action) => {
        if (isBottomSheet) {
            postponedActions.current.push(action);
        } else {
            action();
        }
    };

    const sendResponse = (values) => {
        if (isMagritte && !!responseStep && responseStep !== ResponseStep.Initial) {
            // Tapping on "Save" button during `ResponseStep.Letter` step causes form submit when displaying BottomSheet.
            return;
        }

        if (!isQuestionResponse && vacancy['@responseLetterRequired'] && !letterText.trim().length) {
            onError(ResponseError.LetterRequired);
            return;
        }

        setSubmitting(true);
        onError(null);

        const skipTest = !!values?.skipTest;
        const formdata = new FormData((!skipTest && formRef.current) || undefined);
        const data = {
            vacancy_id: vacancyId, // eslint-disable-line camelcase
            resume_hash: selectedResume.hash, // eslint-disable-line camelcase
            ignore_postponed: true, // eslint-disable-line camelcase
            incomplete: selectedResume.isIncomplete,
            mark_applicant_visible_in_vacancy_country: countriesProfileVisibilityAgreement?.confirmed === true, // eslint-disable-line camelcase
            letter: isQuestionResponse ? questionText : letterText,
            lux: true,
            withoutTest: skipTest ? 'yes' : 'no',
            hhtmFromLabel,
            hhtmSourceLabel,
        };

        if (isQuestionResponse) {
            data.response_source = 'APPLICANT_QUESTIONS'; // eslint-disable-line camelcase
        }

        Object.keys(data).forEach((item) => {
            formdata.append(item, data[item]);
        });

        fetcher
            .post('/applicant/vacancy_response/popup', formdata)
            .then(
                ({ data }) => {
                    LocalStorageWrapper.removeItem(
                        isQuestionResponse ? localstorageQuestionKey : localstorageLetterKey
                    );
                    onResponse && onResponse();

                    if (data.topic_id === 'SPAM') {
                        return;
                    }

                    const chatId = data.chat_id ? Number(data.chat_id) : undefined;

                    const actions = [
                        vacancyResponseUpdate({ vacancyId, data: data.responseStatus }),
                        addUserLabelsForVacancies({
                            vacancyId,
                            labels: isQuestionResponse ? [UserLabel.Question] : [UserLabel.Response],
                        }),
                        applicantActivityAction(data.applicantActivity),
                    ];

                    if (data.applicantActivity) {
                        actions.push(applicantActivityAction(data.applicantActivity));
                    }

                    if (isQuestionResponse) {
                        actions.push(vacancyChatInfoUpdate({ data: { type: 'question', chatId }, vacancyId }));
                    }

                    if (needRedirect) {
                        let params = '';
                        if ('responsesStreak' in data) {
                            params =
                                `?responsesCount=${data.responsesStreak.responsesCount}&responsesRequired=` +
                                `${data.responsesStreak.responsesRequired}`;
                        }
                        data.sendGAAnalyticsToStorage = true;
                        createNotification({
                            isQuestionResponse,
                            dispatch,
                            data,
                            employerId: vacancy.company?.id,
                            publishedForResponseResumeHash,
                            topLevelSite,
                            addNotification,
                            openChatik: () => openChatik?.({ chatId, hhtmFromLabel: 'response_notification' }),
                        });
                        dispatch(push(`/vacancy/${vacancyId}${params}`));
                    } else if ('responsesStreak' in data) {
                        data.sendGAAnalyticsToStorage = false;
                        createNotification({
                            isQuestionResponse,
                            dispatch,
                            data,
                            employerId: vacancy.company?.id,
                            setResponseData: isBottomSheet ? setResponseData : undefined,
                            publishedForResponseResumeHash,
                            topLevelSite,
                            addNotification,
                            openChatik: () => openChatik?.({ chatId, hhtmFromLabel: 'response_notification' }),
                        });
                        !isQuestionResponse &&
                            actions.push(responseStreakUpdate({ vacancyId, data: data.responsesStreak }));
                    }

                    if (!isQuestionResponse) {
                        // Для повторного отклика ставим выбранным первое неиспользованное резюме
                        const unusedResumes = resumes.filter((resume) => resume.id !== selectedResume.id);
                        if (unusedResumes.length > 0) {
                            setSelectedResume(unusedResumes[0]);
                        }
                    }

                    UXFeedback.sendEvent('vacancy_response_action');
                    runActionFinally(() => dispatch(actions));

                    if (!needRedirect) {
                        runActionFinally(() => scrollToRespondedBlock(vacancyBodyFooterNodeRef));
                    }
                },
                ({ response }) => {
                    const code = response?.data?.error;

                    if (response?.data?.type === NEED_LOGIN_CODE && response?.data?.redirect_uri) {
                        return dispatch(push(response.data.redirect_uri));
                    }

                    if (response && code === 'resume-incomplete' && 'redirectUrl' in response.data) {
                        return dispatch(push(response.data.redirectUrl));
                    }

                    return onError(code || 'unknown');
                }
            )
            .finally(() => {
                setSubmitting(false);
            });
    };

    const renderFormMagritte = (form) => {
        if (responseStep === ResponseStep.Letter) {
            return (
                <VacancyResponseFormLetter
                    value={letterText}
                    onChange={setLetterText}
                    required={isLetterRequired}
                    maxLength={responseStatus.letterMaxLength}
                    isBottomSheet={isBottomSheet}
                    defaultExpanded={true}
                />
            );
        }

        return (
            <VSpacingContainer default={24} s={12} xs={12}>
                <VacancyResponseFormAlreadyRespondedPlate
                    visible={alreadyResponded && !submitting}
                    topics={topics}
                    resumes={responseStatus.resumes}
                    isFromPopup={isFromPopup}
                />
                {!!resumes.length && (
                    <ResumeSelector
                        responseStatus={responseStatus}
                        resumes={resumes}
                        selectedResume={selectedResume}
                        setSelectedResume={setSelectedResume}
                        isQuestionResponse={isQuestionResponse}
                    />
                )}
                <VacancyResponseFormLetter
                    value={isQuestionResponse ? questionText : letterText}
                    onChange={isQuestionResponse ? setQuestionText : setLetterText}
                    isQuestionLetter={isQuestionResponse}
                    required={isQuestionResponse || isLetterRequired}
                    maxLength={responseStatus.letterMaxLength}
                    isBottomSheet={isBottomSheet}
                    defaultExpanded={
                        isQuestionResponse || isLetterRequired || responseQuestion?.id === ResponseQuestion.Other
                    }
                />
                {!isQuestionResponse && !isSkipInconsistencies && (
                    <VacancyResponseFormInconsistencies
                        inconsistencies={responseStatus.resumeInconsistencies}
                        selectedResumeId={selectedResume.id}
                        vacancyId={vacancyId}
                        resumes={resumes}
                    />
                )}
                {renderSubmit({ form })}
            </VSpacingContainer>
        );
    };

    const renderFormBloko = (form) => {
        return (
            <div className="vacancy-response-popup-body">
                <VacancyResponseFormAlreadyRespondedPlate
                    visible={alreadyResponded && !submitting}
                    topics={topics}
                    resumes={responseStatus.resumes}
                    isFromPopup={isFromPopup}
                />
                {!!resumes.length && (
                    <div>
                        <VSpacing base={2} />
                        {!isBottomSheet && (
                            <ResumeSelector
                                isQuestionResponse={isQuestionResponse}
                                responseStatus={responseStatus}
                                resumes={resumes}
                                selectedResume={selectedResume}
                                setSelectedResume={setSelectedResume}
                            />
                        )}
                        <VacancyResponseFormLetter
                            value={isQuestionResponse ? questionText : letterText}
                            onChange={isQuestionResponse ? setQuestionText : setLetterText}
                            isQuestionLetter={isQuestionResponse}
                            required={isQuestionResponse || vacancy['@responseLetterRequired']}
                            maxLength={responseStatus.letterMaxLength}
                            isBottomSheet={isBottomSheet}
                            defaultExpanded={isQuestionResponse || responseQuestion?.id === ResponseQuestion.Other}
                        />
                        {!isQuestionResponse && !isBottomSheet && (
                            <VacancyResponseFormInconsistencies
                                inconsistencies={responseStatus.resumeInconsistencies}
                                selectedResumeId={selectedResume.id}
                                vacancyId={vacancyId}
                                resumes={resumes}
                            />
                        )}
                    </div>
                )}
                {renderSubmit({ form })}
            </div>
        );
    };

    const renderForm = () => (
        <FinalForm
            onSubmit={sendResponse}
            initialValues={{ skipTest: false }}
            decorators={decorators}
            render={({ handleSubmit, form }) => (
                <Form onSubmit={handleSubmit} ref={formRef} name={'vacancy_response'} id={FORM_ID}>
                    {renderContent(form)}
                    {isMagritte ? renderFormMagritte(form) : renderFormBloko(form)}
                </Form>
            )}
        />
    );

    return render({
        renderForm,
        formId: FORM_ID,
        isLetterTextEmpty: isQuestionResponse ? !questionText.length : !letterText.length,
    });
};

ResponseForm.propTypes = {
    vacancyId: PropTypes.number,
    resumes: PropTypes.arrayOf(PropTypes.object),
    submitting: PropTypes.bool,
    setSubmitting: PropTypes.func,
    onResponse: PropTypes.func,
    errorCode: PropTypes.string,
    onError: PropTypes.func,
    vacancyBodyFooterNodeRef: PropTypes.object,
    submitTriggerRef: PropTypes.object,
    needRedirect: PropTypes.bool,
    isBottomSheet: PropTypes.bool,
    isSkipInconsistencies: PropTypes.bool,
    setResponseData: PropTypes.func,
    selectedResume: PropTypes.object,
    setSelectedResume: PropTypes.func,
    trls: PropTypes.object,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    vacancySource: PropTypes.oneOf(Object.values(Source)),
    isQuestionResponse: PropTypes.bool,
    isFromPopup: PropTypes.bool,
};

export default translation(ResponseForm);
